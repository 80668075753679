<template>
  <div class="TakeNoteForm">
    <TakeNoteForm />
  </div>
</template>
<script>
// @ is an alias to /src
import TakeNoteForm from "@/components/TakeNoteForm.vue";

export default {
  name: "TakeNoteView",
  components: {
    TakeNoteForm,
  },
};
</script>
