<template>
  <div>
    <b-card style="box-shadow: 1px 1px 12px 5px darkgrey; border: 0px solid rgba(0, 0, 0, 0.125);">
      <h5>เลือกคำตอบที่เหมาะสมกับคุณ</h5>
      <b-row>
        <b-col cols="12">
          <div>
           
           
          <b-form-group style="color:#EA6B0D;" 
          v-for="question in questions" 
          :key="question.title" 
          :label="question.title">

           <b-form-radio style="color:#000;" 
           v-model="question.isTrue" 
           :value="true" 
           :inline="true">ใช่
          </b-form-radio>
           <b-form-radio style="color: #000;"
            v-model="question.isTrue" 
            :value="false"
            :inline="true">
            ไม่ใช่</b-form-radio>
</b-form-group>

        
                     
          </div>
          
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>

export default {
  name: "TheAssessmentFormCard",
  props: {
    questions: Array,
    
  },
  data(){
  return{
    question: {
      isTrue:'',
    }
  }
}
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #EA6B0D;
    background-color: #EA6B0D;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #EA6B0D !important;
    background-color: #EA6B0D !important;
}
.warning{
    color: #EA6B0D;
  }

  .padding{
    padding-bottom:20px;
    padding-top: 20px;
  }
  .btn-warning {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
  }

    .btn-warning:hover {
    color: #EA6B0D;
    background-color: #ffffff;
    border-color: #EA6B0D;
  } 
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
  }
  .btn-warning:focus, .btn-warning.focus {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
    box-shadow: 0 0 0 0.2rem rgba(239, 166, 20, 0.5);
  }
</style>
