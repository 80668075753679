<template>
  <div class="NoTakeNoteForm">
    <NoTakeNoteForm />
  </div>
</template>
<script>
// @ is an alias to /src
import NoTakeNoteForm from "@/components/NoTakeNoteForm.vue";

export default {
  name: "NoTakeNoteView",
  components: {
    NoTakeNoteForm,
  },
};
</script>
