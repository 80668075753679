import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import TakeNoteView from "../views/TakeNoteView.vue";
import NoTakeNoteView from "../views/NoTakeNoteView";
import TheAssessmentFormCard from "../components/TheAssessmentFormCard.vue";

Vue.use(VueRouter);



const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/TakeNoteForm",
    component: TakeNoteView,
  },
  {
    path: "/NoTakeNoteForm",
    component: NoTakeNoteView,
  },
  {
    path: "/assessment",
    name: "assessment",
    component: TheAssessmentFormCard,
  },

  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
