
<template>
  <div class="the-home">
        <header class="header">
      <a href="https://inflowaccount.co.th/">
        <img :src="require('@/assets/new_logo-1.png')" alt="Logo" class="logo" />
      </a>
      <h1></h1>
    </header>
    <div class="container" style="margin-top: 100px;">
      <b-row >
        <b-col class="text-center" cols="12">
          <!-- <h1>แบบประเมิน คุณควรจดทะเบียนบริษัทไหม</h1> -->
          <h2 :class="maincolor">แบบประเมิน คุณควรจดทะเบียนบริษัทไหม</h2>
          <img class="img img-desktop img-mobile" src="../assets/head-line.png" alt="Store Images"/>
        </b-col>
      </b-row>
      <b-row>
        <ValidationObserver v-slot="{ handleSubmit, valid }">
  <b-col>
    <TheAssessmentFormCard :questions="questions" />
  </b-col>

  <b-col class="text-center padding" cols="12">
    <b-button
      variant="warning"
      @click="handleSubmit(submit)"

      type="submit"
      :class="{ 'btn-disabled': !valid }"
    >
      <img class="img" src="../assets/icon-view.png" alt="Store Images" height="25px" width="30px" /> ประมวลผล
    </b-button>&nbsp;&nbsp;&nbsp;
    <b-button variant="secondary" @click="refreshComponent">ล้างข้อมูล</b-button>
  </b-col>
</ValidationObserver>
      </b-row>
      <b-row>
      </b-row>
    </div>
  </div>
  
</template>
<script>

import TheAssessmentFormCard from "./TheAssessmentFormCard.vue";

// import { required, minLength } from 'vuelidate/lib/validators'
export default {
  name: "TheHome",
  components: {
    TheAssessmentFormCard,
  },
  data() {
    
    return {
      
      questions: [
        
        {          
          title:
            "1.คุณมีรายได้ต่อปีเกิน 1.8 ล้านบาทหรือไม่",
          isTrue: null,
          ishighRisk: true, 
          
           
        },
        {
          title:
            "2.ลูกค้าของคุณส่วนใหญ่ต้องการใบกำกับภาษีใช่หรือไม่",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "3.กิจการคุณมีต้นทุนทางตรงของกิจการสูงกว่า 60% หรือไม่",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "4.คุณเสียภาษีเงินได้บุคคลธรรมดาเกิน 115,000 บาทต่อปี",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "5.คุณเคยถูกหักภาษี ณ ที่จ่าย จากบริษัทผู้ว่าจ้างบ้างหรือไม่",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "6.คุณมีรายได้มากกว่า 1 ช่องทางหรือไม่",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "7.กิจการของคุณมีเจ้าของกิจการมากกว่า 1 คนหรือไม่",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "8.กิจการของคุณต้องการความน่าเชื่อถือในการประกอบธุรกิจใช่หรือไม่",
          isTrue: null,
          ishighRisk: false,
        },
        {
          title:
            "9.คุณต้องการที่จะทำกิจการนี้ให้เป็นรายได้หลักของตัวเองใช่หรือไม่",
          isTrue: null,
          ishighRisk: false,
        },
      ],
      maincolor:"maincolor",
      warning:"warning"
    };
  },

  methods: {
    submit() {
  const hasYesAnswer = this.questions.slice(0, 4).some(question => question.isTrue);
  const allQuestionsAnswered = this.questions.every(question => question.isTrue !== null);
  
  if (!allQuestionsAnswered) {
    alert("กรุณาตอบคำถามทั้งหมด");
    return;
  }
  
  if (hasYesAnswer) {
    this.$router.push("/TakeNoteForm").catch(() => {});
  } else {
    this.$router.push("/NoTakeNoteForm").catch(() => {});
  }
},
   
refreshComponent() {
  this.questions.forEach(question => {
    question.isTrue = null;
  });
},
    isDisabled() {
      return false
    },
    calculateRisk() {
      this.questions.some(question => {
        console.log(question.title)
        if(question.isTrue && question.ishighRisk) {
          this.$router.push("/TakeNoteForm").catch(()=>{});
          return true     
        }else{
          this.$router.push("/NoTakeNoteForm").catch(()=>{});
          return false
        }
      })
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row{
  display: flex;
    justify-content: center;
    align-items: center;
}
.btn-disabled {
  background-color: gray;
  color: white;
}

  .maincolor{
  color:#EA6B0D;
  }
  .warning{
    color: #EA6B0D;
  }

  .padding{
    padding-bottom:20px;
    padding-top: 20px;
  }
  .btn-warning {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
  }

    .btn-warning:hover {
    color: #EA6B0D;
    background-color: #ffffff;
    border-color: #EA6B0D;
  } 
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
  }
  .btn-warning:focus, .btn-warning.focus {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
    box-shadow: 0 0 0 0.2rem rgba(239, 166, 20, 0.5);
  }
  .btn-secondary:hover {
    color: #5a6268;
    background-color: #ffffff;
    border-color: #545b62;
}
a:hover {
    color: #0056b3;
    text-decoration: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #EA6B0D !important;
    background-color: #EA6B0D !important;
}
.img-desktop{
  padding-bottom: 30px; 
  width: 60%;
}
.header {
    background-color: rgb(0 0 0 / 79%);
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .logo {
    width: auto; 
    height: auto;
  }
  .main-content {
    margin-top: 70px; 
  }
@media (max-width: 768px) {
  .img-mobile{
  padding-bottom: 30px; 
  width: 100%;
}
}

</style>
